import { ContentTemplateModel, ContentType, InteractionEventType } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { apiService } from "../../services";
import { ContentTemplate } from "./components/ContentTemplate.component";
import { getContentTemplate, getContentTemplates } from "./ContentTemplate.services";
import { useParams } from "react-router-dom";

export const useContentTemplate = (identifier: string | null, contentType: ContentType | null = null, campaignIdentifier: string | null = null) => {
  const [contentTemplate, setContentTemplate] = useState<ContentTemplateModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { campaignId } = useParams();

  useEffect(() => {
    setIsLoading(true);

    if (identifier != null) {
      getContentTemplate(identifier, campaignIdentifier)
        .then((contentTemplate: any) => {
          setContentTemplate(contentTemplate);
        })
        .catch((err: any) => {
          console.error(err);
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (contentType != null) {
      getContentTemplates(contentType, campaignId)
        .then((contentTemplates: Array<ContentTemplateModel>) => {
          setContentTemplate(contentTemplates[0]);
        })
        .catch((err: any) => {
          console.error(err);
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

  }, []);

  useEffect(() => {
    if (!isLoading && contentTemplate && contentTemplate.template && contentTemplate.type.toUpperCase() === "ARTICLE") {
      apiService.instance.createInteractionSimple(InteractionEventType.AppArticleView, contentTemplate.identifier);
    }
  }, [isLoading, contentTemplate]);

  const ContentTemplateContent = () => {
    return (
      <>
        {(contentTemplate !== undefined) ? <ContentTemplate {...contentTemplate} /> : <></>}
      </>
    )
  }

  return {
    isLoading,
    hasError,
    hasContent: !!contentTemplate,
    ContentTemplateContent
  }
}