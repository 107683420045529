import React from 'react';
import { faEdit, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Card, GridContainerNoFlex, LoaderAnimated } from '../../components';
import { OpportunityStatus, SalesIncentiveOpportunityModel } from 'outbound-api-client/lib/models';
import { useOpportunities } from './DealReg.hooks';
import { useContentTemplate } from '../ContentTemplate';
import { configService } from '../../services';
import { DealSummarySection } from './DealSummary.styles';
import { DealSummary } from './DealSummary.component';
import { LogDealActionBar } from './LogDealActionBar.component';
import { MyDealsTable } from './MyDeals.styles';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DealRegFormProps, LayoutType } from '../../services/config.service.types';
import { useAppDispatch } from '../../store';
import { useCampaignDispatch } from '../Campaigns';

export const MyDeals: React.FC = () => {
  const { campaignId } = useParams();
  const { opportunities, hasError, isLoading } = useOpportunities(campaignId || null, null);
  const [ sortedField, setSortedField ] = React.useState<string>("opportunityDate");
  const [ sortedDirection, setSortedDirection ] = React.useState<string>("asc");
  const { ContentTemplateContent } = useContentTemplate("My_Deals_EN_GB");
  const isLegacy = configService.current().layoutType == LayoutType.Legacy;
  const dispatch = useAppDispatch();
  const { campaign } = useCampaignDispatch(dispatch, campaignId || '');
  const playbookUri = (!campaign) ? [] : campaign.contentUris.filter(c => /Playbook/i.test(c.identifier));

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  let sortedOpportunities = [...opportunities];
  if (sortedField !== null) {

    switch (sortedField)
    {
      case "opportunityDate":
        sortedOpportunities.sort((a: SalesIncentiveOpportunityModel, b: SalesIncentiveOpportunityModel) => {
          if (a.opportunityDate < b.opportunityDate) {
            return (sortedDirection == "asc" ? -1 : 1);
          }
          if (a.opportunityDate > b.opportunityDate) {
            return (sortedDirection == "asc" ? 1 : -1);
          }
          return 0;
        });
        break;
        case "oem":
        sortedOpportunities.sort((a: SalesIncentiveOpportunityModel, b: SalesIncentiveOpportunityModel) => {
          if (a.oem < b.oem) {
            return (sortedDirection == "asc" ? -1 : 1);
          }
          if (a.oem > b.oem) {
            return (sortedDirection == "asc" ? 1 : -1);
          }
          return 0;
        });
        break;

        case "status":
        sortedOpportunities.sort((a: SalesIncentiveOpportunityModel, b: SalesIncentiveOpportunityModel) => {
          if (a.status < b.status) {
            return (sortedDirection == "asc" ? -1 : 1);
          }
          if (a.status > b.status) {
            return (sortedDirection == "asc" ? 1 : -1);
          }
          return 0;
        });
        break;

        case "customerName":
        sortedOpportunities.sort((a: SalesIncentiveOpportunityModel, b: SalesIncentiveOpportunityModel) => {
          if (a.customerName < b.customerName) {
            return (sortedDirection == "asc" ? -1 : 1);
          }
          if (a.customerName > b.customerName) {
            return (sortedDirection == "asc" ? 1 : -1);
          }
          return 0;
        });
        break;

        case "productType":
        sortedOpportunities.sort((a: SalesIncentiveOpportunityModel, b: SalesIncentiveOpportunityModel) => {
          if (a.products[0].description < b.products[0].description) {
            return (sortedDirection == "asc" ? -1 : 1);
          }
          if (a.products[0].description > b.products[0].description) {
            return (sortedDirection == "asc" ? 1 : -1);
          }
          return 0;
        });
        break;

        case "value":
        sortedOpportunities.sort((a: SalesIncentiveOpportunityModel, b: SalesIncentiveOpportunityModel) => {
          if (a.value < b.value) {
            return (sortedDirection == "asc" ? -1 : 1);
          }
          if (a.value > b.value) {
            return (sortedDirection == "asc" ? 1 : -1);
          }
          return 0;
        });
        break;
    }

    
  }

  function hasVendors() { return configService.current().opportunityManagementProps.vendors.length > 0 };

  return (
    <div className='d-flex justify-content-center flex-column'>
      <Helmet>
        <title>{configService.current().applicationTitle} - My Deals</title>
      </Helmet>
      {(!opportunities || isLoading) && !hasError ? (
        <LoaderAnimated />
      ) : opportunities ? (
        <>
          <LogDealActionBar />
          <div className='text-center'>
            <ContentTemplateContent />
          </div>
          <DealSummarySection>

          {configService.current().campaignProps.dealSummarySections.map((dealSummary) => { return <DealSummary mode={dealSummary.mode} title={dealSummary.title} logoUrl={dealSummary.logoUrl} oem={dealSummary.oem} playbookUrl={playbookUri[0].uri ?? dealSummary.playbookUrl} borderColor={dealSummary.borderColour} /> })}

          </DealSummarySection>
          {(!isLegacy) ?
            <MyDealsTable cellPadding={0} cellSpacing={0}>
              <thead className='text-center'>
                <tr>
                {(configService.current().opportunityManagementProps.dealRegForm?.filter((drf: DealRegFormProps) => drf.campaignIdentifier == null || drf.campaignIdentifier == campaignId)[0].dealRegForm?.map(field => { return <th>{field.label}</th>}))}
                </tr>
              </thead>
              <tbody>
                {opportunities.map((o: SalesIncentiveOpportunityModel, inx: number) => (
                  <tr key={inx} className={`row-${o.oem}`}>
                    {(hasVendors()) ? 
                  <td className='text-center'>
                  <img src={`https://sccgt23.blob.core.windows.net/images/logo-${o.oem.toLowerCase()}2.png`} />
                </td> : <></> }
                    
                    <td className='text-center'>
                      {o.status}
                    </td>
                    <td className='text-center'>
                      {o.customerName}
                    </td>
                    <td className='text-center'>
                      {o.details}
                    </td>
                    <td className='text-center'>
                      {o.products[0].description}
                    </td>
                    <td className='text-center'>
                      {(configService.current().clientId == "canonfocuspep") ? o.value : formatter.format(o.value) }
                    </td>
                    <td className='text-center'>
                      <Moment format="DD/MM/YYYY">{o.opportunityDate}</Moment>
                    </td>
                    {(configService.current().opportunityManagementProps.dealRegForm?.filter((drf: DealRegFormProps) => drf.campaignIdentifier == null || drf.campaignIdentifier == campaignId)[0]?.dealsEditable || o.status == "OPEN") ? <td className='text-center'>
                    <Link to={`../dealreg/${o.opportunityId}`} relative="path"><FontAwesomeIcon icon={faEdit} style={{color: "#000"}} /></Link>
                    </td> : <></>}
                    
                  </tr>
                ))}
              </tbody>

            </MyDealsTable> :

            <GridContainerNoFlex className='m-x-auto p-top-1'>
              <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2 m-top-2 d-flex justify-content-center'>
              <MyDealsTable cellPadding={0} cellSpacing={0}>
                <thead className='text-center'>
                  <tr>
                  {(hasVendors()) ? 
                  <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "oem") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("oem"); setSortedDirection("asc") }}}>Vendor</span>{(sortedField=="oem") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th> : <></> }
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "status") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("status"); setSortedDirection("asc") }}}>Status</span>{(sortedField=="status") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "customerName") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("customerName"); setSortedDirection("asc") }}}>Customer Name</span>{(sortedField=="customerName") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "productType") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("productType"); setSortedDirection("asc") }}}>Product Type</span>{(sortedField=="productType") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "value") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("value"); setSortedDirection("asc") }}}>Estimated Sale Value (&pound;)</span>{(sortedField=="value") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "opportunityDate") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("opportunityDate"); setSortedDirection("asc") }}}>Estimated Close Date</span>{(sortedField=="opportunityDate") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedOpportunities.map((o: SalesIncentiveOpportunityModel, inx: number) => (
                    <tr key={inx} className={`row-${o.oem}`}>
                      {(hasVendors()) ? 
                  <td className='logo-container'>
                  <img src={`https://sccgt23.blob.core.windows.net/images/logo-${o.oem.toLowerCase()}2.png`} />
                  </td> : <></> }
                      <td className='text-center'>
                        {o.status}
                      </td>
                      <td className='text-center'>
                        {o.customerName}
                      </td>
                      <td className='text-center'>
                        {o.products[0].description}
                      </td>
                      <td className='text-center'>
                        {formatter.format(o.value)}
                      </td>
                      <td className='text-center'>
                        <Moment format="DD/MM/YYYY">{o.opportunityDate}</Moment>
                      </td>
                      <td className='text-center'>
                        {(o.status == OpportunityStatus.Open) ? 
                        <Link to={`../dealreg/${o.opportunityId}`} relative="path"><FontAwesomeIcon icon={faEdit} style={{color: "#000"}} /></Link>
                        : <></>}      
                      </td>
                    </tr>
                  ))}
                </tbody>

              </MyDealsTable>
              </Card>
            </GridContainerNoFlex>
          }

        </>
      ) : (
        <div><p>Error</p></div>
      )}
    </div>
  )
}