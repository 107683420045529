import React from 'react';
import { CampaignModel as CampaignProps } from 'outbound-api-client/lib/models';
import { Button, Card, Content } from '../../components';
import { DealSummary } from '../OpportunityManagement';
import { DealSummarySection, PrizeSummarySection } from './Campaign.styles';
import { useNavigate, useParams} from 'react-router-dom';
import { configService } from '../../services';
import { LayoutType } from '../../services/config.service.types';
import { useCampaignDispatch } from './Campaigns.hooks';
import { useAppDispatch } from '../../store';

export const CampaignHome: React.FC<CampaignProps> = (props) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const { campaign } = useCampaignDispatch(dispatch, campaignId || '');
  const playbookUri = (!campaign) ? [] : campaign.contentUris.filter(c => /Playbook/i.test(c.identifier));
  
  const navigatePrizes = () => {
    navigate("article/Prize_Page_EN_GB");
  }

  return (
    <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2' hideWrapper={configService.current().layoutType != LayoutType.Legacy}>
      <h2 className="text-center font-title  m-bottom-0">{props.title}</h2>
      <div className='p-x-1'>
        {!!props.template?.length && (
          <Content template={props.template} />
        )}
      </div>
      <DealSummarySection>
        {configService.current().campaignProps.dealSummarySections.map((dealSummary) => { 
          return <DealSummary mode={dealSummary.mode} title={dealSummary.title} logoUrl={dealSummary.logoUrl} oem={dealSummary.oem} playbookUrl={playbookUri[0].uri} borderColor={dealSummary.borderColour} /> })}
      </DealSummarySection>

      {(configService.current().campaignProps.prizeSection.show) ?
        <PrizeSummarySection>
          <div
            className="prizes-prize-container">
            <div
              className="prize-description">
              <h3>{configService.current().campaignProps.prizeSection.title}</h3>
              <p>{configService.current().campaignProps.prizeSection.intro}</p>
              <Button primary onClick={navigatePrizes}>DISCOVER</Button>
            </div>
          </div>
        </PrizeSummarySection> : <></>}

    </Card>
  )
}